import React from 'react';

import {
  TextField,
  usePermissions,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
  styled,
} from '@mui/material';

import EditField from '../../../../customFields/EditField';
import hasAccess from '../../../../utilities/hasAccess';
import PerformKyc from '../../../../customFields/PerformKyc';
import RiskManagement from '../../../riskManagment';
import { Actions } from '../../../../constants/actions';
import EditArrayInput from '../../../../customFields/EditArrayInput';

const Title = styled(Typography)({
  fontWeight: '400',
  fontSize: '13px',
  color: '#646464',
});

const General = () => {
  const { permissions } = usePermissions<Actions[]>();

  return (
    <Grid item xs={12} lg={5}>
      <Card variant="outlined">
        <CardHeader
          title="General"
          action={
            hasAccess(permissions, 'RequestCreate') && (<PerformKyc />)
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box>
                <Title>ID</Title>
                <TextField source="id" />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <EditArrayInput showEdit={hasAccess(permissions, 'CarUpdate')} source="customIds" label="Custom IDs" />
            </Grid>
            <Grid item xs={12} md={12}>
              <EditField showEdit={hasAccess(permissions, 'CarUpdate')} source="registrationNumber" label="Registration Number" />
            </Grid>
            <Grid item xs={12} md={12}>
              <EditField showEdit={hasAccess(permissions, 'CarUpdate')} source="registrationCountry" label="Registration Country" />
            </Grid>
            <Grid item xs={12} md={12}>
              <EditField showEdit={hasAccess(permissions, 'CarUpdate')} source="vinCode" label="Win code" />
            </Grid>
            <Grid item xs={12} md={12}>
              <EditField showEdit={hasAccess(permissions, 'CarUpdate')} source="carModel" label="model" />
            </Grid>
            <Grid item xs={12} md={12}>
              <RiskManagement />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default General;
