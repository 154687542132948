import { ValueOf } from '../utilities/types';

const datapointNames = {
  REGISTRATION_NUMBER: 'organizationNumber', // To rename
  COMPANY_NAME: 'companyName',
  RESIDENCE: 'residence',
  ADDRESS: 'address',
  ACTIVITIES: 'activities',
  TIN: 'tin',
  POSTAL_CODE: 'postalCode',
  CITY: 'city',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  WEBSITE: 'website',
  CITIZENSHIP: 'citizenship',
  SIGNATORIES: 'signatories',
  INCOME: 'salary', // TODO: to rename
  ALTERNATIVE_BENEFICIAL_OWNERS: 'alternativeBeneficialOwners',
  BENEFICIAL_OWNER: 'beneficialOwner', // Is this necessary
  BENEFICIAL_OWNERS: 'beneficialOwners',
  TAX_RESIDENCY: 'taxResidency',
  TRANSACTION_MONITORING_THRESHOLD: 'transactionMonitoringThresholds',
  DATE_OF_BIRTH: 'dateOfBirth',
  EMPLOYMENT_STATUS: 'employmentStatus',
  FIRST_NAME: 'firstName',
  MIDDLE_NAME: 'middleName',
  LAST_NAME: 'lastName',
  INDUSTRY: 'industry',
  IS_FINANCIAL_INSTITUTION: 'isFinancialInstitution',
  LEGAL_FORM: 'legalForm',
  OTHER_LEGAL_FORM: 'otherLegalForm',
  PRODUCTS: 'products',
  PURPOSE: 'purpose',
  SOURCE_OF_FUNDS: 'sourceOfFunds',
  PEP: 'pep',
  OWNER_STRUCTURE: 'ownerStructure',
  ROLE: 'role',
  OPERATION_START_DATE: 'operationStartDate',
  LISTED_FINANCIAL_MARKET: 'listedFinancialMarket',
  STATE_OWNED: 'stateOwned',
  PREVIOUS_TURNOVER: 'perviousTurnover', // typo in name
  FORECASTED_TURNOVER: 'forecastedTurnover',
  SOURCE_OF_WEALTH: 'sourceOfWealth',
} as const;

export type DatapointNames = ValueOf<typeof datapointNames>;

export default datapointNames;
