import { RequestRoles } from '../../../constants/requestRoles';
import { RequestTypes } from '../../../constants/requestTypes';
import ruleNames from '../../../constants/ruleNames';
import ruleRequestEntityFields from '../ruleRequestEntityFields';
import { RequestRules } from './requestRules';

const rulePartiesToCheck: Record<
RequestRules,
Record<RequestTypes, RequestRoles[]> | undefined
> = {
  [ruleNames.ADDITIONAL_DELIVERY_ADDRESS]: ruleRequestEntityFields,
  [ruleNames.AFFORDABILITY]: ruleRequestEntityFields,
  [ruleNames.AGE_THRESHOLD]: ruleRequestEntityFields,
  [ruleNames.AMOUNT_THRESHOLD]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM]: ruleRequestEntityFields,
  [ruleNames.ANOMALY_DETECTION]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_ACTIVE]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_AUDITOR]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_EQUITY_SHARE]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_EQUITY]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_FTAX]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_PAYMENT_REMARKS_SUM]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_PAYMENT_REMARKS]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_PROVIDER_WARNING]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_SANCTION_LIST]: ruleRequestEntityFields,
  [ruleNames.BUSINESS_VAT]: ruleRequestEntityFields,
  [ruleNames.CAR_FREQ_HISTORICAL_OWNERS]: undefined,
  [ruleNames.CAR_GUARANTOR_RELATED]: ruleRequestEntityFields,
  [ruleNames.CAR_IN_CIRCULATION]: undefined,
  [ruleNames.CAR_VALUATION]: undefined,
  [ruleNames.CASH_FLOW]: ruleRequestEntityFields,
  [ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD]: ruleRequestEntityFields,
  [ruleNames.CO_ADDRESS]: ruleRequestEntityFields,
  [ruleNames.COLLECTOR]: ruleRequestEntityFields,
  [ruleNames.CONNECTED_BUYER_SELLER]: undefined,
  [ruleNames.CONNECTED_ENTITY_PEP]: ruleRequestEntityFields,
  [ruleNames.CONNECTED_ENTITY_SANCTION_LIST]: ruleRequestEntityFields,
  [ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST]: ruleRequestEntityFields,
  [ruleNames.COUNTRY_RESIDENCE_BLACKLIST]: ruleRequestEntityFields,
  [ruleNames.COUNTRY_RESIDENCE_WHITELIST]: ruleRequestEntityFields,
  [ruleNames.DATAPOINT_CONFLICT]: ruleRequestEntityFields,
  [ruleNames.EMPLOYMENT_TYPE]: ruleRequestEntityFields,
  [ruleNames.FOREIGN_ADDRESS]: ruleRequestEntityFields,
  [ruleNames.INCOMING_CATEGORIZATION_FREQUENCY]: ruleRequestEntityFields,
  [ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM]: ruleRequestEntityFields,
  [ruleNames.INCOMING_FREQUENCY]: ruleRequestEntityFields,
  [ruleNames.INCOMING_VOLUME_ROLLING_SUM]: ruleRequestEntityFields,
  [ruleNames.INDIVIDUAL_CRIMINAL_RECORD]: ruleRequestEntityFields,
  [ruleNames.INDIVIDUAL_PEP]: ruleRequestEntityFields,
  [ruleNames.INDIVIDUAL_SANCTION_LIST]: ruleRequestEntityFields,
  [ruleNames.IP_COUNTRY_BLACKLIST]: ruleRequestEntityFields,
  [ruleNames.KYC_VALID]: ruleRequestEntityFields,
  [ruleNames.LOW_INCOME]: ruleRequestEntityFields,
  [ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY]: ruleRequestEntityFields,
  [ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM]: ruleRequestEntityFields,
  [ruleNames.OUTGOING_FREQUENCY]: ruleRequestEntityFields,
  [ruleNames.OUTGOING_VOLUME_ROLLING_SUM]: ruleRequestEntityFields,
  [ruleNames.PROCESSING_FREQUENCY]: ruleRequestEntityFields,
  [ruleNames.PROCESSING_VOLUME_ROLLING_SUM]: ruleRequestEntityFields,
  [ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD]: ruleRequestEntityFields,
  [ruleNames.SELLER_OWNS_CAR]: undefined,
  [ruleNames.SNI_CODE_BLACKLIST]: ruleRequestEntityFields,
  [ruleNames.SOURCE_OF_FUNDS]: ruleRequestEntityFields,
  [ruleNames.SOURCE_OF_INCOME]: ruleRequestEntityFields,
  [ruleNames.SUSPICIOUS_FUNDS]: ruleRequestEntityFields,
  [ruleNames.TEST_BLOCK]: ruleRequestEntityFields,
  [ruleNames.TEST_REVIEW]: ruleRequestEntityFields,
  [ruleNames.TIME_WINDOW]: ruleRequestEntityFields,
  [ruleNames.TOTAL_CATEGORIZATION_FREQUENCY]: ruleRequestEntityFields,
  [ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM]: ruleRequestEntityFields,
  [ruleNames.TOTAL_FREQUENCY]: ruleRequestEntityFields,
  [ruleNames.TOTAL_VOLUME_ROLLING_SUM]: ruleRequestEntityFields,
  [ruleNames.ZIP_CODE_BLACKLIST]: ruleRequestEntityFields,
};

export default rulePartiesToCheck;
