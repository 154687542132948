import React from 'react';
import {
  ArrayField,
  SelectField,
  SingleFieldList,
} from 'react-admin';

import {
  Box,
  Chip,
  Grid,
  Typography,
} from '@mui/material';

import { BUSINESS_DATAPOINT_CONFLICT_FIELDS, INDIVIDUAL_DATAPOINT_CONFLICT_FIELDS } from '../ruleTypesForm/ruleSpecific/DatapointConflict';

const DatapointConflictParams = () => (
  <>
    <Grid item xs={12} sm={6}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <Typography fontWeight="bold" color="black">Individual monitored datapoint</Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={2} alignItems="center" flexWrap="wrap">
          <ArrayField source="ruleParameters.individualMonitoredDatapoints">
            <SingleFieldList linkType={false}>
              <Chip
                color="default"
                label={<SelectField choices={INDIVIDUAL_DATAPOINT_CONFLICT_FIELDS} source="datapointName" />}
              />
            </SingleFieldList>
          </ArrayField>
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <Typography fontWeight="bold" color="black">Business monitored datapoint</Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={2} alignItems="center" flexWrap="wrap">
          <ArrayField source="ruleParameters.businessMonitoredDatapoints">
            <SingleFieldList linkType={false}>
              <Chip
                color="default"
                label={<SelectField choices={BUSINESS_DATAPOINT_CONFLICT_FIELDS} source="datapointName" />}
              />
            </SingleFieldList>
          </ArrayField>
        </Box>
      </Box>
    </Grid>
  </>
);

export default DatapointConflictParams;
