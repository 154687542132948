import React from 'react';

import {
  ListBase,
} from 'react-admin';

import {
  Dialog,
  DialogTitle,
  Card,
  DialogContent,
} from '@mui/material';

import Pagination from '../layout/Pagination';

import { requestTypes } from '../../constants/requestTypes';
import TransactionList from './Transaction';

const ReferenceDetailedTransactionTable = ({
  data,
  open,
  closeDialog,
}: {
  data?: {
    x: string,
    y: number,
    transactions: unknown[]
    currency: string
  }
  open: boolean,
  closeDialog: () => void,
}) => (
  <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xl">
    <DialogTitle color="secondary">Detailed Transactions</DialogTitle>
    <DialogContent>
      {data && (
        <Card variant="outlined">
          <ListBase
            filter={{ requestType: requestTypes.TRANSACTION, id: data.transactions }}
            perPage={5}
            resource="requests"
            sort={{ field: 'extraInformation.date', order: 'DESC' }}
          >
            <TransactionList />
            <Pagination disableEmptyText rowsPerPageOptions={[5, 10, 20]} />
          </ListBase>
        </Card>
      )}
    </DialogContent>
  </Dialog>
);

export default ReferenceDetailedTransactionTable;
