import requestTypes, { RequestTypes } from '../../../constants/requestTypes';
import ruleNames from '../../../constants/ruleNames';
import { RequestRules } from './requestRules';

const requestTypesSupported: Record<RequestRules, {
  id: RequestTypes,
  name: string;
}[]> = {
  [ruleNames.ADDITIONAL_DELIVERY_ADDRESS]:
    [requestTypes.TRANSACTION, requestTypes.INDIVIDUAL_KYC, requestTypes.CAR_PURCHASE],
  [ruleNames.AFFORDABILITY]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.AGE_THRESHOLD]:
    [requestTypes.TRANSACTION, requestTypes.INDIVIDUAL_KYC, requestTypes.CAR_PURCHASE],
  [ruleNames.AMOUNT_THRESHOLD]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE]: [requestTypes.TRANSACTION],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY]: [requestTypes.TRANSACTION],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT]: [requestTypes.TRANSACTION],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM]: [requestTypes.TRANSACTION],
  [ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ANOMALY_DETECTION]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.BUSINESS_ACTIVE]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.BUSINESS_AUDITOR]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.BUSINESS_EQUITY_SHARE]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.BUSINESS_EQUITY]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.BUSINESS_FTAX]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.BUSINESS_PAYMENT_REMARKS_SUM]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.BUSINESS_PAYMENT_REMARKS]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.BUSINESS_PROVIDER_WARNING]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.BUSINESS_SANCTION_LIST]:
    [requestTypes.TRANSACTION, requestTypes.BUSINESS_KYC, requestTypes.CAR_PURCHASE],
  [ruleNames.BUSINESS_VAT]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.CAR_FREQ_HISTORICAL_OWNERS]: [requestTypes.CAR_PURCHASE, requestTypes.CAR_KYC],
  [ruleNames.CAR_GUARANTOR_RELATED]: [requestTypes.CAR_PURCHASE],
  [ruleNames.CAR_IN_CIRCULATION]: [requestTypes.CAR_PURCHASE, requestTypes.CAR_KYC],
  [ruleNames.CAR_VALUATION]: [requestTypes.CAR_PURCHASE],
  [ruleNames.CASH_FLOW]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.CO_ADDRESS]: [
    requestTypes.TRANSACTION,
    requestTypes.INDIVIDUAL_KYC,
    requestTypes.BUSINESS_KYC,
    requestTypes.CAR_PURCHASE,
  ],
  [ruleNames.COLLECTOR]: [
    requestTypes.TRANSACTION,
    requestTypes.INDIVIDUAL_KYC,
    requestTypes.CAR_PURCHASE,
    requestTypes.BUSINESS_KYC,
  ],
  [ruleNames.CONNECTED_BUYER_SELLER]: [requestTypes.CAR_PURCHASE],
  [ruleNames.CONNECTED_ENTITY_PEP]: [
    requestTypes.TRANSACTION,
    requestTypes.CAR_PURCHASE,
    requestTypes.BUSINESS_KYC,
  ],
  [ruleNames.CONNECTED_ENTITY_SANCTION_LIST]: [
    requestTypes.TRANSACTION,
    requestTypes.CAR_PURCHASE,
    requestTypes.BUSINESS_KYC,
  ],
  [ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST]: [
    requestTypes.INDIVIDUAL_KYC,
    requestTypes.TRANSACTION,
    requestTypes.BUSINESS_KYC,
    requestTypes.CAR_PURCHASE,
  ],
  [ruleNames.COUNTRY_RESIDENCE_BLACKLIST]: [
    requestTypes.INDIVIDUAL_KYC,
    requestTypes.TRANSACTION,
    requestTypes.BUSINESS_KYC,
    requestTypes.CAR_PURCHASE,
  ],
  [ruleNames.COUNTRY_RESIDENCE_WHITELIST]: [
    requestTypes.INDIVIDUAL_KYC,
    requestTypes.TRANSACTION,
    requestTypes.BUSINESS_KYC,
    requestTypes.CAR_PURCHASE,
  ],
  [ruleNames.DATAPOINT_CONFLICT]: [
    requestTypes.TRANSACTION,
    requestTypes.INDIVIDUAL_KYC,
    requestTypes.CAR_PURCHASE,
    requestTypes.BUSINESS_KYC,
  ],
  [ruleNames.EMPLOYMENT_TYPE]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.INDIVIDUAL_KYC],
  [ruleNames.FOREIGN_ADDRESS]:
    [requestTypes.TRANSACTION, requestTypes.INDIVIDUAL_KYC, requestTypes.CAR_PURCHASE],
  [ruleNames.INCOMING_CATEGORIZATION_FREQUENCY]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.INCOMING_FREQUENCY]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.INCOMING_VOLUME_ROLLING_SUM]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.INDIVIDUAL_CRIMINAL_RECORD]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.INDIVIDUAL_KYC],
  [ruleNames.INDIVIDUAL_PEP]:
    [requestTypes.TRANSACTION, requestTypes.INDIVIDUAL_KYC, requestTypes.CAR_PURCHASE],
  [ruleNames.INDIVIDUAL_SANCTION_LIST]:
    [requestTypes.TRANSACTION, requestTypes.INDIVIDUAL_KYC, requestTypes.CAR_PURCHASE],
  [ruleNames.IP_COUNTRY_BLACKLIST]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.KYC_VALID]: [
    requestTypes.TRANSACTION,
    requestTypes.CAR_PURCHASE,
    requestTypes.INDIVIDUAL_KYC,
    requestTypes.BUSINESS_KYC,
  ],
  [ruleNames.LOW_INCOME]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.INDIVIDUAL_KYC],
  [ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.OUTGOING_FREQUENCY]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.OUTGOING_VOLUME_ROLLING_SUM]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.PROCESSING_FREQUENCY]: [requestTypes.TRANSACTION],
  [ruleNames.PROCESSING_VOLUME_ROLLING_SUM]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE, requestTypes.BUSINESS_KYC],
  [ruleNames.SELLER_OWNS_CAR]: [requestTypes.CAR_PURCHASE],
  [ruleNames.SOURCE_OF_FUNDS]: [
    requestTypes.TRANSACTION,
    requestTypes.CAR_PURCHASE,
    requestTypes.INDIVIDUAL_KYC,
    requestTypes.BUSINESS_KYC,
  ],
  [ruleNames.SOURCE_OF_INCOME]: [
    requestTypes.TRANSACTION,
    requestTypes.CAR_PURCHASE,
    requestTypes.INDIVIDUAL_KYC,
    requestTypes.BUSINESS_KYC,
  ],
  [ruleNames.SNI_CODE_BLACKLIST]:
    [requestTypes.TRANSACTION, requestTypes.BUSINESS_KYC, requestTypes.CAR_PURCHASE],
  [ruleNames.SUSPICIOUS_FUNDS]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.TEST_BLOCK]: Object.values(requestTypes),
  [ruleNames.TEST_REVIEW]: Object.values(requestTypes),
  [ruleNames.TIME_WINDOW]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.TOTAL_CATEGORIZATION_FREQUENCY]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM]:
    [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.TOTAL_FREQUENCY]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.TOTAL_VOLUME_ROLLING_SUM]: [requestTypes.TRANSACTION, requestTypes.CAR_PURCHASE],
  [ruleNames.ZIP_CODE_BLACKLIST]: [
    requestTypes.INDIVIDUAL_KYC,
    requestTypes.TRANSACTION,
    requestTypes.BUSINESS_KYC,
    requestTypes.CAR_PURCHASE,
  ],
};

export default requestTypesSupported;
