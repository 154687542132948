import React from 'react';

import {
  ChipField,
  ReferenceArrayField,
  SelectField,
  SingleFieldList,
  useRecordContext,
} from 'react-admin';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Card, Divider, Grid, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  get,
  isEmpty,
  isUndefined,
  toLower,
  uniq,
} from 'lodash';
import requestRolesChoices from '../../../../../constants/requestRoles';
import ruleRequestEntityFields from '../../../ruleRequestEntityFields';
import assignmentTrigger, { AssignmentTrigger, assignmentTriggerChoices } from '../../../../../constants/assignmentTrigger';

type EntityToCheck = {
  _id?: string;
  role: string;
  groupsFiltering: {
    [role: string]: {
      shouldFilterOnGroups?: boolean;
      groupsToInclude?: string[]
      groupsToIncludeCondition?: string;
      groupsToExclude?: string[]
      groupsToExcludeCondition?: string;
    } | undefined;
  };
  groupsAssignment: {
    [role: string]: {
      assignmentTrigger?: AssignmentTrigger;
      groupsToAddOnTrigger?: string[];
      groupsToRemoveOnTrigger?: string[]
    } | undefined;
  }
}

const EntitiesToCheck = () => {
  const record = useRecordContext();

  if (isEmpty(record?.entitiesToCheck)) return null;

  const potentialRoles = uniq(Object.values(ruleRequestEntityFields).flat());

  return record?.entitiesToCheck.map((field: EntityToCheck, index: number) => (
    <Card variant="outlined" square sx={{ mt: 1 }} key={field._id ?? index}>
      <Accordion key={`Entity to Check #${index + 1}`} sx={{ maxWidth: null, backgroundColor: 'transparent' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
            variant="description"
            color="primary"
          >
            {`Entity to Check #${index + 1}`}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ p: '2%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" marginBottom={2}>
                Role to check
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="label">
                  Which role in the request should be checked by the rule
                </Typography>
                <SelectField
                  source={`entitiesToCheck.[${index}].role`}
                  choices={Object.values(requestRolesChoices)}
                  label="Role"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" marginTop={4} marginBottom={2}>
                Group filtering
              </Typography>
            </Grid>
            {potentialRoles
              .filter((role) => !isUndefined(field.groupsFiltering[role]?.shouldFilterOnGroups))
              .map((role) => (
                <React.Fragment key={role}>
                  <Grid item xs={12} md={12}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography variant="label">
                        Would you like to filter the
                        {' '}
                        {toLower(role)}
                        {' '}
                        on groups?
                      </Typography>
                      <SelectField
                        source={`entitiesToCheck.[${index}].groupsFiltering.${role}.shouldFilterOnGroups`}
                        choices={[
                          { id: true, name: 'Yes' },
                          { id: false, name: 'No' },
                        ]}
                      />
                    </Box>
                  </Grid>
                  {get(record, `entitiesToCheck.[${index}].groupsFiltering.${role}.shouldFilterOnGroups`) && (
                    <Grid item container xs={12}>
                      <Box padding={4} sx={{ backgroundColor: 'greenGray.main', borderRadius: 2 }} width="100%">
                        <Grid item xs={12} md={12} marginBottom={2}>
                          <Box display="flex" flexDirection="column" gap={2}>
                            <Typography variant="label">
                              Specify the groups that the
                              {' '}
                              {toLower(role)}
                              {' '}
                              must be included in
                              {' '}
                              <b>to execute</b>
                              {' '}
                              the rule
                            </Typography>
                            {!isEmpty(get(record, `entitiesToCheck.[${index}].groupsFiltering.${role}.groupsToInclude`)) && (
                              <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                                <ReferenceArrayField
                                  emptyText="-"
                                  reference="groups"
                                  source={`entitiesToCheck.[${index}].groupsFiltering.${role}.groupsToInclude`}
                                >
                                  <SingleFieldList>
                                    <ChipField source="name" emptyText="-" />
                                  </SingleFieldList>
                                </ReferenceArrayField>
                                {get(record, `entitiesToCheck.[${index}].groupsFiltering.${role}.groupsToInclude`).length > 1 && (
                                  <SelectField
                                    source={`entitiesToCheck.[${index}].groupsFiltering.${role}.groupsToIncludeCondition`}
                                    choices={[
                                      { id: 'or', name: '(Any of them)' },
                                      { id: 'and', name: '(All of them)' },
                                    ]}
                                  />
                                )}
                              </Box>
                            )}
                            {isEmpty(get(record, `entitiesToCheck.[${index}].groupsFiltering.${role}.groupsToInclude`)) && (
                              <Typography>All</Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box display="flex" flexDirection="column" gap={2}>
                            <Typography variant="label">
                              Specify the groups that the
                              {' '}
                              {toLower(role)}
                              {' '}
                              must be included in
                              {' '}
                              <b>to NOT execute</b>
                              {' '}
                              the rule
                            </Typography>
                            {!isEmpty(get(record, `entitiesToCheck.[${index}].groupsFiltering.${role}.groupsToExclude`)) && (
                              <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                                <ReferenceArrayField
                                  emptyText="-"
                                  reference="groups"
                                  source={`entitiesToCheck.[${index}].groupsFiltering.${role}.groupsToExclude`}
                                >
                                  <SingleFieldList>
                                    <ChipField source="name" emptyText="-" />
                                  </SingleFieldList>
                                </ReferenceArrayField>
                                {get(record, `entitiesToCheck.[${index}].groupsFiltering.${role}.groupsToExclude`).length > 1 && (
                                  <SelectField
                                    source={`entitiesToCheck.[${index}].groupsFiltering.${role}.groupsToExcludeCondition`}
                                    choices={[
                                      { id: 'or', name: '(Any of them)' },
                                      { id: 'and', name: '(All of them)' },
                                    ]}
                                  />
                                )}
                              </Box>
                            )}
                            {isEmpty(get(record, `entitiesToCheck.[${index}].groupsFiltering.${role}.groupsToExclude`)) && (
                              <Typography>None</Typography>
                            )}
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            <Grid item xs={12} md={12}>
              <Typography variant="h6" marginTop={4} marginBottom={2}>
                Group assignment
              </Typography>
            </Grid>
            {potentialRoles
              .filter((role) => !isUndefined(
                field.groupsAssignment[role]?.assignmentTrigger,
              ))
              .map((role) => (
                <React.Fragment key={role}>
                  <Grid item xs={12} md={12}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography variant="label">
                        On what event would you like to add or remove groups for the
                        {' '}
                        {toLower(role)}
                        ?
                      </Typography>
                      <SelectField
                        source={`entitiesToCheck.[${index}].groupsAssignment.${role}.assignmentTrigger`}
                        choices={assignmentTriggerChoices}
                      />
                    </Box>
                  </Grid>
                  {[assignmentTrigger.ON_EXECUTION, assignmentTrigger.ON_TRIGGER, assignmentTrigger.ON_PING].some((trigger) => trigger === get(record, `entitiesToCheck.[${index}].groupsAssignment.${role}.assignmentTrigger`)) && (
                    <Grid item container xs={12}>
                      <Box padding={4} sx={{ backgroundColor: 'greenGray.main', borderRadius: 2 }} width="100%">
                        <Grid item xs={12} md={12} marginBottom={2}>
                          <Box display="flex" flexDirection="column" gap={2}>
                            <Typography variant="label">
                              Specify the groups to add to the
                              {' '}
                              {toLower(role)}
                            </Typography>
                            {!isEmpty(get(record, `entitiesToCheck.[${index}].groupsAssignment.${role}.groupsToAddOnTrigger`)) && (
                              <ReferenceArrayField emptyText="-" label="Groups" reference="groups" source={`entitiesToCheck.[${index}].groupsAssignment.${role}.groupsToAddOnTrigger`}>
                                <SingleFieldList>
                                  <ChipField source="name" emptyText="-" />
                                </SingleFieldList>
                              </ReferenceArrayField>
                            )}
                            {isEmpty(get(record, `entitiesToCheck.[${index}].groupsAssignment.${role}.groupsToAddOnTrigger`)) && (
                              <Typography>None</Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box display="flex" flexDirection="column" gap={2}>
                            <Typography variant="label">
                              Specify the groups to remove from the
                              {' '}
                              {toLower(role)}
                            </Typography>
                            {!isEmpty(get(record, `entitiesToCheck.[${index}].groupsAssignment.${role}.groupsToRemoveOnTrigger`)) && (
                              <ReferenceArrayField emptyText="-" label="Groups" reference="groups" source={`entitiesToCheck.[${index}].groupsAssignment.${role}.groupsToRemoveOnTrigger`}>
                                <SingleFieldList>
                                  <ChipField source="name" emptyText="-" />
                                </SingleFieldList>
                              </ReferenceArrayField>
                            )}
                            {isEmpty(get(record, `entitiesToCheck.[${index}].groupsAssignment.${role}.groupsToRemoveOnTrigger`)) && (
                              <Typography>None</Typography>
                            )}
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
          </Grid>
        </AccordionDetails>

      </Accordion>
    </Card>
  ));
};

export default EntitiesToCheck;
