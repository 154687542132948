import React, { useCallback, useState } from 'react';

import {
  List,
  regex,
  required,
  SelectInput,
  TextInput,
  useListFilterContext,
} from 'react-admin';

import {
  Card,
  Divider,
  Tooltip,
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';

import { SubListNavigation } from '../layout/SubNavigation';
import { StyledTab, StyledTabs } from '../layout/Tab';
import Pagination from '../layout/Pagination';

import requestTypesChoices, { requestTypes, RequestTypes } from '../../constants/requestTypes';
import { requestListMap } from '../../constants/requestFeatures';
import EntityFilter from '../filters/EntityFilter';
import recommendations, { Recommendations } from '../../constants/recommendations';

type RequestFilters = {
  requestType: RequestTypes;
  'custom.entity'?: string[]
  recommendation?: Recommendations;
  processStopped: boolean;
};

const RequestListBody = () => {
  const { filterValues } = useListFilterContext();
  const { requestType } = filterValues as RequestFilters;

  const RequestListComponent = requestListMap[requestType];
  if (!RequestListComponent) return null;

  return <RequestListComponent />;
};

const RequestList = () => {
  const [recommendationTab, setRecommendationTab] = useState(0);
  const [
    recommendation,
    setRecommendationFilter,
  ] = useState<Recommendations | undefined>(undefined);
  const [processStopped, setProcessStopped] = useState(false);

  const handleChange = useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setRecommendationTab(newValue);
    if (newValue === 0) {
      setRecommendationFilter(undefined);
      setProcessStopped(false);
    }
    if (newValue === 1) {
      setRecommendationFilter(recommendations.REVIEW);
      setProcessStopped(false);
    }
    if (newValue === 2) {
      setRecommendationFilter(recommendations.PROCEED);
      setProcessStopped(false);
    }
    if (newValue === 3) {
      setRecommendationFilter(recommendations.BLOCK);
      setProcessStopped(false);
    }
    if (newValue === 4) {
      setProcessStopped(true);
      setRecommendationFilter(undefined);
    }
  }, []);

  return (
    <List
      filter={{ recommendation, processStopped }}
      empty={false}
      pagination={<Pagination disableEmptyText />}
      filterDefaultValues={{ requestType: requestTypes.TRANSACTION }}
      filters={[
        <SelectInput alwaysOn source="requestType" choices={Object.values(requestTypesChoices)} validate={required()} />,
        <TextInput
          alwaysOn
          label="Id"
          source="_id"
          validate={regex(/^[0-9a-fA-F]{24}$/, 'Must be a valid objectID')}
          sx={{
            '& .MuiFormHelperText-root': {
              display: 'unset',
            },
          }}
        />,
        <TextInput alwaysOn label="Custom Id" source="customId" />,
        <EntityFilter label="Entities" source="custom.entity" />,
      ]}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
    >
      <Card sx={{ margin: 0 }} variant="outlined">
        <Card sx={{ margin: 0 }}>
          <SubListNavigation>
            <StyledTabs value={recommendationTab} onChange={handleChange}>
              <StyledTab label="All" />
              <StyledTab label="Review " />
              <StyledTab label="Proceed" />
              <StyledTab label="Block" />
              <StyledTab
                icon={(
                  <Tooltip title="Requests that have not been correctly processed">
                    <InfoIcon color="primary" />
                  </Tooltip>
                )}
                iconPosition="end"
                label="Stopped"
              />
            </StyledTabs>
          </SubListNavigation>
          <Divider />
        </Card>
        <RequestListBody />
      </Card>
    </List>
  );
};

export default RequestList;
