import { ValueOf } from '../utilities/types';

const assignmentTrigger = {
  NONE: 'none',
  ON_PING: 'on-ping',
  ON_TRIGGER: 'on-trigger',
  ON_EXECUTION: 'on-execution',
} as const;

export type AssignmentTrigger = ValueOf<typeof assignmentTrigger>;

export const assignmentTriggerChoices = [
  { id: assignmentTrigger.NONE, name: 'None' },
  { id: assignmentTrigger.ON_PING, name: 'On Ping' },
  { id: assignmentTrigger.ON_TRIGGER, name: 'On Trigger' },
  { id: assignmentTrigger.ON_EXECUTION, name: 'On Execution' },
];

export default assignmentTrigger;
