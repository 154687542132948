import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

import {
  isArray, isString, startCase, isObject, get, lowerCase,
} from 'lodash';

import {
  RaRecord,
} from 'react-admin';
import ruleParameters, { RuleParameters } from '../../../constants/ruleParams';
import { Rule } from '../../../utilities/schemas/rules';
import { mapRuleParams } from '../ruleFeatures';

const ruleDataTransform = (data: RaRecord | string, param: string) => {
  const regex = /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/; // special characters'
  if (param.includes('commonThreshold')) {
    const global = get(data, 'global', null);
    if (global) return `${global}`;
    return 'Risk-based';
  }

  if (isArray(data)) {
    if ([ruleParameters.FORM_THRESHOLDS.id, ruleParameters.DEFAULT_THRESHOLDS.id]
      .some((field) => field === param as RuleParameters['id'])) {
      return data.map((val) => Object.values(val).map(((v) => (isObject(v) ? 'Risk-based' : `${v}`))).join(': ')).join(', ');
    }
    if (ruleParameters.LOWEST_ACCEPTABLE_INCOME.id === param) {
      return data.map((threshold) => `${threshold.currency}: ${threshold.amount} / ${threshold.unitOfTime}`).join(', ');
    }

    return data.map((item) => lowerCase(startCase(item))).join(', ');
  }
  if (isString(data) && regex.test(data)) return data;
  if (isString(data)) return startCase(data);
  return data;
};

const RuleParams = ({
  param,
  rule,
  fullWidth = false,
}: {
  fullWidth?: boolean,
  param: {
    id: string,
    name: string,
    unit?: string,
    subIds?: readonly string[]
    riskBased?: boolean
  },
  rule: Rule
}) => {
  if (!rule) return null;

  const data = param?.id?.split('.').reduce((o, i) => get(o, i, null), rule?.ruleParameters);

  if (!data) return null;
  const ruleValue = ruleDataTransform(data, param?.id);

  if (!ruleValue) return null;
  const string = param?.subIds
    ? param?.subIds?.map((id) => data[id]).join(' ')
    : `${ruleValue} ${param?.unit ?? ''}`;

  return (
    <Grid item xs={12} sm={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 4} alignItems="baseline">
      <Box display="flex" flexDirection="row" gap={1} alignItems="first-baseline">
        <Typography fontWeight="bold" fontSize={10}>
          {param?.name}
          :
        </Typography>
        <Typography fontSize={10}>
          {string}
        </Typography>
      </Box>
    </Grid>
  );
};

const Default = ({
  rule,
}: {
  rule: Rule
}) => mapRuleParams[rule?.name!]?.map((param) => (
  <RuleParams
    key={param?.id}
    param={param}
    rule={rule}
  />
));

export default Default;
