import questions, { Questions } from '../../constants/questions';

const getQuestionPathMap: Record<Questions, (
  extraParameters: unknown,
) => string | false> = {
  [questions.Q_ACTIVITIES]: () => 'activities',
  [questions.Q_ADDRESS]: () => 'address',
  [questions.Q_ASSIGNED_OWNERS]: () => 'alternativeBeneficialOwners',
  [questions.Q_BENEFICIAL_OWNER]: () => 'beneficialOwner',
  [questions.Q_BENEFICIAL_OWNERS]: () => 'beneficialOwners',
  [questions.Q_CITIZENSHIP]: () => 'citizenship',
  [questions.Q_CITY]: () => 'city',
  [questions.Q_COMPANY_NAME]: () => 'companyName',
  [questions.Q_CONTROL_TYPE]: () => questions.Q_CONTROL_TYPE,
  [questions.Q_CUSTOM]: (extraParameters) => `custom.${(extraParameters as { id: string }).id}`,
  [questions.Q_DATE_OF_BIRTH]: () => 'dateOfBirth',
  [questions.Q_EMAIL]: () => 'email',
  [questions.Q_EMPLOYMENT_STATUS]: () => 'employmentStatus',
  [questions.Q_FAMILY_CONTROL]: () => questions.Q_FAMILY_CONTROL,
  [questions.Q_FIRST_NAME]: () => 'firstName',
  [questions.Q_FILE_UPLOAD]: (extraParameters) => `fileUpload.${(extraParameters as { id: string }).id}`,
  [questions.Q_FORECASTED_TURNOVER]: () => 'forecastedTurnover',
  [questions.Q_INDUSTRY]: () => 'industry',
  [questions.Q_IS_FINANCIAL_INSTITUTION]: () => 'isFinancialInstitution',
  [questions.Q_LAST_NAME]: () => 'lastName',
  [questions.Q_LEGAL_FORM]: () => 'legalForm',
  [questions.Q_LISTED_FINANCIAL_MARKET]: () => 'listedFinancialMarket',
  [questions.Q_OPERATION_START_DATE]: () => 'operationStartDate',
  [questions.Q_ORG_NUMBER]: () => 'organizationNumber',
  [questions.Q_OWNER_STRUCTURE]: () => 'ownerStructure',
  [questions.Q_PEP]: () => 'pep',
  [questions.Q_PERSONAL_NUMBER]: () => 'personalNumber',
  [questions.Q_PHONE_NUMBER]: () => 'phoneNumber',
  [questions.Q_POSTAL_CODE]: () => 'postalCode',
  [questions.Q_PREVIOUS_TURNOVER]: () => 'previousTurnover',
  [questions.Q_PRODUCTS]: () => 'products',
  [questions.Q_PURPOSE]: () => 'purpose',
  [questions.Q_RESIDENCE]: () => 'residence',
  [questions.Q_ROLE]: () => 'role',
  [questions.Q_SALARY]: () => 'salary',
  [questions.Q_SIGNATORIES]: () => 'signatories',
  [questions.Q_SIGNER]: () => 'signer',
  // Cannot find the exact answer for this question. Dependent answers are always shown
  [questions.Q_EXPECTED_TRANSACTION_BEHAVIOUR]: () => false,
  [questions.Q_SOURCE_OF_FUNDS]: () => 'sourceOfFunds',
  [questions.Q_SOURCE_OF_WEALTH_AMOUNT]: () => 'sourceOfWealthAmount',
  [questions.Q_SOURCE_OF_WEALTH]: () => 'sourceOfWealth',
  [questions.Q_STATE_OWNED]: () => 'stateOwned',
  [questions.Q_TAX_RESIDENCY]: () => 'taxResidency',
  [questions.Q_TIN]: () => 'tin',
  [questions.Q_WEBSITE]: () => 'website',
};

export default getQuestionPathMap;
