import ruleNames, { RuleNames } from '../../../constants/ruleNames';

export const mapBinaryRule: Record<RuleNames, boolean> = {
  [ruleNames.ADDITIONAL_DELIVERY_ADDRESS]: true,
  [ruleNames.AFFORDABILITY]: false,
  [ruleNames.AGE_THRESHOLD]: true,
  [ruleNames.AMOUNT_THRESHOLD]: false,
  [ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE]: false,
  [ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY]: false,
  [ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT]: false,
  [ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM]: false,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE]: false,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY]: false,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT]: false,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM]: false,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE]: false,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY]: false,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT]: false,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM]: false,
  [ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE]: false,
  [ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY]: false,
  [ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT]: false,
  [ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM]: false,
  [ruleNames.ANOMALY_DETECTION]: false,
  [ruleNames.BUSINESS_ACTIVE]: true,
  [ruleNames.BUSINESS_AUDITOR]: true,
  [ruleNames.BUSINESS_DATA_CHANGED]: true,
  [ruleNames.BUSINESS_EQUITY_SHARE]: true,
  [ruleNames.BUSINESS_EQUITY]: true,
  [ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT]: true,
  [ruleNames.BUSINESS_FTAX]: true,
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM]: true,
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS]: true,
  [ruleNames.BUSINESS_PAYMENT_REMARKS_SUM]: true,
  [ruleNames.BUSINESS_PAYMENT_REMARKS]: true,
  [ruleNames.BUSINESS_PROVIDER_WARNING]: true,
  [ruleNames.BUSINESS_SANCTION_LIST]: true,
  [ruleNames.BUSINESS_VAT]: true,
  [ruleNames.CAR_FREQ_HISTORICAL_OWNERS]: false,
  [ruleNames.CAR_GUARANTOR_RELATED]: true,
  [ruleNames.CAR_IN_CIRCULATION]: true,
  [ruleNames.CAR_VALUATION]: true,
  [ruleNames.CASH_FLOW]: false,
  [ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD]: false,
  [ruleNames.CO_ADDRESS]: true,
  [ruleNames.COLLECTOR]: true,
  [ruleNames.CONNECTED_BUYER_SELLER]: true,
  [ruleNames.CONNECTED_ENTITY_PEP]: true,
  [ruleNames.CONNECTED_ENTITY_SANCTION_LIST]: true,
  [ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST]: true,
  [ruleNames.COUNTRY_RESIDENCE_BLACKLIST]: true,
  [ruleNames.COUNTRY_RESIDENCE_WHITELIST]: true,
  [ruleNames.DATAPOINT_CONFLICT]: true,
  [ruleNames.EMPLOYMENT_TYPE]: true,
  [ruleNames.FOREIGN_ADDRESS]: true,
  [ruleNames.INCOMING_CATEGORIZATION_FREQUENCY]: false,
  [ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM]: false,
  [ruleNames.INCOMING_FREQUENCY]: false,
  [ruleNames.INCOMING_VOLUME_ROLLING_SUM]: false,
  [ruleNames.INDIVIDUAL_CRIMINAL_RECORD]: true,
  [ruleNames.INDIVIDUAL_PEP]: true,
  [ruleNames.INDIVIDUAL_SANCTION_LIST]: true,
  [ruleNames.IP_COUNTRY_BLACKLIST]: true,
  [ruleNames.KYC_VALID]: true,
  [ruleNames.LOW_INCOME]: true,
  [ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY]: false,
  [ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM]: false,
  [ruleNames.OUTGOING_FREQUENCY]: false,
  [ruleNames.OUTGOING_VOLUME_ROLLING_SUM]: false,
  [ruleNames.PROCESSING_FREQUENCY]: true,
  [ruleNames.PROCESSING_VOLUME_ROLLING_SUM]: true,
  [ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD]: true,
  [ruleNames.SELLER_OWNS_CAR]: true,
  [ruleNames.SNI_CODE_BLACKLIST]: true,
  [ruleNames.SOURCE_OF_FUNDS]: true,
  [ruleNames.SOURCE_OF_INCOME]: true,
  [ruleNames.SPAR_CHANGED]: true,
  [ruleNames.SUSPICIOUS_FUNDS]: false,
  [ruleNames.TEST_BLOCK]: false,
  [ruleNames.TEST_REVIEW]: false,
  [ruleNames.TIME_WINDOW]: false,
  [ruleNames.TOTAL_CATEGORIZATION_FREQUENCY]: false,
  [ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM]: false,
  [ruleNames.TOTAL_FREQUENCY]: false,
  [ruleNames.TOTAL_VOLUME_ROLLING_SUM]: false,
  [ruleNames.ZIP_CODE_BLACKLIST]: true,
};

export default mapBinaryRule;
