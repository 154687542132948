import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Rule } from '../../../utilities/schemas/rules';
import { BUSINESS_DATAPOINT_CONFLICT_FIELDS, INDIVIDUAL_DATAPOINT_CONFLICT_FIELDS } from '../ruleTypesForm/ruleSpecific/DatapointConflict';
import { ConflictFields } from '../../../constants/conflictFields';

const getLabel = (choices: { id: string; name: string; }[], value: string) => choices.find(({ id }) => id === value)?.name ?? '';

type DatapointConflictParam = {
  individualMonitoredDatapoints: {
    datapointName: ConflictFields
  }[]
  businessMonitoredDatapoints: {
    datapointName: ConflictFields
  }[]
};

const DatapointConflicts = ({ rule }: {
  rule: Rule
}) => {
  const individualDatapoints = (
    rule.ruleParameters as DatapointConflictParam
  ).individualMonitoredDatapoints
    .map((datapoint) => getLabel(INDIVIDUAL_DATAPOINT_CONFLICT_FIELDS, datapoint.datapointName)).join(', ');
  const businessDatapoints = (
    rule.ruleParameters as DatapointConflictParam
  ).businessMonitoredDatapoints
    .map((datapoint) => getLabel(BUSINESS_DATAPOINT_CONFLICT_FIELDS, datapoint.datapointName)).join(', ');
  return (
    <>
      <Grid item xs={12} sm={6} lg={4} alignItems="baseline">
        <Box display="flex" flexDirection="row" gap={1} alignItems="first-baseline">
          <Typography fontWeight="bold" fontSize={10}>
            Individual monitored datapoint:
          </Typography>
          <Typography fontSize={10}>
            {individualDatapoints}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={4} alignItems="baseline">
        <Box display="flex" flexDirection="row" gap={1} alignItems="first-baseline">
          <Typography fontWeight="bold" fontSize={10}>
            Business monitored datapoint:
          </Typography>
          <Typography fontSize={10}>
            {businessDatapoints}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default DatapointConflicts;
