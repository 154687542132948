import ruleNames from '../../../constants/ruleNames';

const requestRules = [
  ruleNames.ADDITIONAL_DELIVERY_ADDRESS,
  ruleNames.AFFORDABILITY,
  ruleNames.AGE_THRESHOLD,
  ruleNames.AMOUNT_THRESHOLD,
  ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE,
  ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY,
  ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT,
  ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM,
  ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE,
  ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY,
  ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT,
  ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM,
  ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE,
  ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY,
  ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT,
  ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM,
  ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE,
  ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY,
  ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT,
  ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM,
  ruleNames.ANOMALY_DETECTION,
  ruleNames.BUSINESS_ACTIVE,
  ruleNames.BUSINESS_AUDITOR,
  ruleNames.BUSINESS_EQUITY_SHARE,
  ruleNames.BUSINESS_EQUITY,
  ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT,
  ruleNames.BUSINESS_FTAX,
  ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM,
  ruleNames.BUSINESS_PAYMENT_APPLICATIONS,
  ruleNames.BUSINESS_PAYMENT_REMARKS_SUM,
  ruleNames.BUSINESS_PAYMENT_REMARKS,
  ruleNames.BUSINESS_PROVIDER_WARNING,
  ruleNames.BUSINESS_SANCTION_LIST,
  ruleNames.BUSINESS_VAT,
  ruleNames.CAR_FREQ_HISTORICAL_OWNERS,
  ruleNames.CAR_GUARANTOR_RELATED,
  ruleNames.CAR_IN_CIRCULATION,
  ruleNames.CAR_VALUATION,
  ruleNames.CASH_FLOW,
  ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD,
  ruleNames.CO_ADDRESS,
  ruleNames.COLLECTOR,
  ruleNames.CONNECTED_BUYER_SELLER,
  ruleNames.CONNECTED_ENTITY_PEP,
  ruleNames.CONNECTED_ENTITY_SANCTION_LIST,
  ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST,
  ruleNames.COUNTRY_RESIDENCE_BLACKLIST,
  ruleNames.COUNTRY_RESIDENCE_WHITELIST,
  ruleNames.DATAPOINT_CONFLICT,
  ruleNames.EMPLOYMENT_TYPE,
  ruleNames.FOREIGN_ADDRESS,
  ruleNames.INCOMING_CATEGORIZATION_FREQUENCY,
  ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM,
  ruleNames.INCOMING_FREQUENCY,
  ruleNames.INCOMING_VOLUME_ROLLING_SUM,
  ruleNames.INDIVIDUAL_CRIMINAL_RECORD,
  ruleNames.INDIVIDUAL_PEP,
  ruleNames.INDIVIDUAL_SANCTION_LIST,
  ruleNames.IP_COUNTRY_BLACKLIST,
  ruleNames.KYC_VALID,
  ruleNames.LOW_INCOME,
  ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY,
  ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM,
  ruleNames.OUTGOING_FREQUENCY,
  ruleNames.OUTGOING_VOLUME_ROLLING_SUM,
  ruleNames.PROCESSING_FREQUENCY,
  ruleNames.PROCESSING_VOLUME_ROLLING_SUM,
  ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD,
  ruleNames.SELLER_OWNS_CAR,
  ruleNames.SNI_CODE_BLACKLIST,
  ruleNames.SOURCE_OF_FUNDS,
  ruleNames.SOURCE_OF_INCOME,
  ruleNames.SUSPICIOUS_FUNDS,
  ruleNames.TEST_BLOCK,
  ruleNames.TEST_REVIEW,
  ruleNames.TIME_WINDOW,
  ruleNames.TOTAL_CATEGORIZATION_FREQUENCY,
  ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM,
  ruleNames.TOTAL_FREQUENCY,
  ruleNames.TOTAL_VOLUME_ROLLING_SUM,
  ruleNames.ZIP_CODE_BLACKLIST,
] as const;

export type RequestRules = typeof requestRules[number];

export default requestRules;
