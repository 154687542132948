import React from 'react';

import ruleNames, { RuleNames } from '../../../constants/ruleNames';
import Default from '../ruleSubtitles/Default';
import DatapointConflictParams from '../ruleSubtitles/DatapointConflicts';
import { Rule } from '../../../utilities/schemas/rules';

export type RuleParams = React.FC<{ rule: Rule }>

const mapRuleParamsSubTitle: Record<RuleNames, RuleParams> = {
  [ruleNames.ADDITIONAL_DELIVERY_ADDRESS]: Default,
  [ruleNames.AFFORDABILITY]: Default,
  [ruleNames.AGE_THRESHOLD]: Default,
  [ruleNames.AMOUNT_THRESHOLD]: Default,
  [ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE]: Default,
  [ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY]: Default,
  [ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT]: Default,
  [ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM]: Default,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE]: Default,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY]: Default,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT]: Default,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM]: Default,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE]: Default,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY]: Default,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT]: Default,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM]: Default,
  [ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE]: Default,
  [ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY]: Default,
  [ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT]: Default,
  [ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM]: Default,
  [ruleNames.ANOMALY_DETECTION]: Default,
  [ruleNames.BUSINESS_ACTIVE]: Default,
  [ruleNames.BUSINESS_AUDITOR]: Default,
  [ruleNames.BUSINESS_DATA_CHANGED]: Default,
  [ruleNames.BUSINESS_EQUITY_SHARE]: Default,
  [ruleNames.BUSINESS_EQUITY]: Default,
  [ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT]: Default,
  [ruleNames.BUSINESS_FTAX]: Default,
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM]: Default,
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS]: Default,
  [ruleNames.BUSINESS_PAYMENT_REMARKS_SUM]: Default,
  [ruleNames.BUSINESS_PAYMENT_REMARKS]: Default,
  [ruleNames.BUSINESS_PROVIDER_WARNING]: Default,
  [ruleNames.BUSINESS_SANCTION_LIST]: Default,
  [ruleNames.BUSINESS_VAT]: Default,
  [ruleNames.CAR_FREQ_HISTORICAL_OWNERS]: Default,
  [ruleNames.CAR_GUARANTOR_RELATED]: Default,
  [ruleNames.CAR_IN_CIRCULATION]: Default,
  [ruleNames.CAR_VALUATION]: Default,
  [ruleNames.CASH_FLOW]: Default,
  [ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD]: Default,
  [ruleNames.CO_ADDRESS]: Default,
  [ruleNames.COLLECTOR]: Default,
  [ruleNames.CONNECTED_BUYER_SELLER]: Default,
  [ruleNames.CONNECTED_ENTITY_PEP]: Default,
  [ruleNames.CONNECTED_ENTITY_SANCTION_LIST]: Default,
  [ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST]: Default,
  [ruleNames.COUNTRY_RESIDENCE_BLACKLIST]: Default,
  [ruleNames.COUNTRY_RESIDENCE_WHITELIST]: Default,
  [ruleNames.DATAPOINT_CONFLICT]: DatapointConflictParams,
  [ruleNames.EMPLOYMENT_TYPE]: Default,
  [ruleNames.FOREIGN_ADDRESS]: Default,
  [ruleNames.INCOMING_CATEGORIZATION_FREQUENCY]: Default,
  [ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM]: Default,
  [ruleNames.INCOMING_FREQUENCY]: Default,
  [ruleNames.INCOMING_VOLUME_ROLLING_SUM]: Default,
  [ruleNames.INDIVIDUAL_CRIMINAL_RECORD]: Default,
  [ruleNames.INDIVIDUAL_PEP]: Default,
  [ruleNames.INDIVIDUAL_SANCTION_LIST]: Default,
  [ruleNames.IP_COUNTRY_BLACKLIST]: Default,
  [ruleNames.KYC_VALID]: Default,
  [ruleNames.LOW_INCOME]: Default,
  [ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY]: Default,
  [ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM]: Default,
  [ruleNames.OUTGOING_FREQUENCY]: Default,
  [ruleNames.OUTGOING_VOLUME_ROLLING_SUM]: Default,
  [ruleNames.PROCESSING_FREQUENCY]: Default,
  [ruleNames.PROCESSING_VOLUME_ROLLING_SUM]: Default,
  [ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD]: Default,
  [ruleNames.SELLER_OWNS_CAR]: Default,
  [ruleNames.SNI_CODE_BLACKLIST]: Default,
  [ruleNames.SOURCE_OF_FUNDS]: Default,
  [ruleNames.SOURCE_OF_INCOME]: Default,
  [ruleNames.SPAR_CHANGED]: Default,
  [ruleNames.SUSPICIOUS_FUNDS]: Default,
  [ruleNames.TEST_BLOCK]: Default,
  [ruleNames.TEST_REVIEW]: Default,
  [ruleNames.TIME_WINDOW]: Default,
  [ruleNames.TOTAL_CATEGORIZATION_FREQUENCY]: Default,
  [ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM]: Default,
  [ruleNames.TOTAL_FREQUENCY]: Default,
  [ruleNames.TOTAL_VOLUME_ROLLING_SUM]: Default,
  [ruleNames.ZIP_CODE_BLACKLIST]: Default,
};

export default mapRuleParamsSubTitle;
