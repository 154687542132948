import { ValueOf } from '../utilities/types';

const entityStatuses = {
  BLOCKED: 'Blocked',
  AVAILABLE: 'Available',
} as const;

export type EntityStatuses = ValueOf<typeof entityStatuses>;

export default entityStatuses;
