import { Box, Typography } from '@mui/material';
import React from 'react';

import {
  RecordContextProvider, ReferenceField, TextField,
} from 'react-admin';
import { Rule } from '../../../../utilities/schemas/rules';
import mapRuleParamsSubTitle from '../../ruleFeatures/mapRuleParamsSubTitle';

const InfoField = ({
  title,
  value,
}: {
  title: string;
  value: string | number
}) => (
  <Box display="flex" gap={1}>
    <Typography fontWeight="bold" fontSize={10}>{title}</Typography>
    <Typography fontSize={10}>{value}</Typography>
  </Box>
);

const SubTitle = ({
  rule,
}: {
  rule: Rule
}) => {
  if (!rule) return null;

  return (
    <RecordContextProvider value={rule}>
      <Box gap={1} paddingTop={3} display="flex" alignItems="baseline">
        <Box display="flex" gap={1}>
          <Typography fontWeight="bold" fontSize={10}>Process</Typography>
          <ReferenceField source="process" reference="processes" emptyText="">
            <TextField size="small" sx={{ fontSize: 10 }} source="name" />
          </ReferenceField>
        </Box>
        {rule.createPing && <InfoField title="Score" value={rule.score} />}
        <InfoField title="Create ping" value={rule.createPing ? 'Yes' : 'No'} />
        {React.createElement(mapRuleParamsSubTitle[rule.name], { rule })}
      </Box>
    </RecordContextProvider>
  );
};

export default SubTitle;
