import React, { useEffect } from 'react';

import {
  Datagrid,
  SelectField,
  TextField,
  useRecordContext,
  ReferenceArrayField,
  SingleFieldList,
  ReferenceField,
  useListContext,
} from 'react-admin';

import {
  Box,
  SxProps,
} from '@mui/material';

import NoResults from '../../layout/NoResults';

import ColoredScoreField from '../../../customFields/ColoredScoreField';
import { RuleTypes } from '../../../constants/ruleTypes';
import TeamChip from '../../../customFields/ColoredChipField';
import { Rule } from '../../../utilities/schemas/rules';
import mapRuleParamsSubTitle from '../ruleFeatures/mapRuleParamsSubTitle';

const Fields = ({ label }: {label: string}) => {
  const rule = useRecordContext<Rule>();

  if (!rule) return null;

  return (
    <Box key={label}>
      {React.createElement(mapRuleParamsSubTitle[rule.name], { rule })}
    </Box>
  );
};

const perPageOptions = [
  5,
  10,
  15,
  30,
  50,
];

const RuleDatagrid = ({
  children,
  expand,
  isRowExpandable,
  sx,
}: {
  children: React.ReactElement | false
  ruleType: RuleTypes
  expand?: React.ReactElement
  isRowExpandable?: (record: Rule) => boolean;
  sx?: SxProps
}) => {
  const { perPage, setPerPage } = useListContext();

  useEffect(() => {
    if (!perPageOptions.includes(perPage)) {
      setPerPage(50);
    }
  }, [perPage, setPerPage]);

  return (
    <Datagrid
      expand={expand}
      isRowExpandable={isRowExpandable}
      empty={<NoResults />}
      bulkActionButtons={false}
      sx={sx}
    >
      <TextField sortable={false} source="id" />
      <TextField sortable={false} source="name" />
      <ReferenceField sortable={false} reference="processes" source="process">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField sortable={false} source="tags" reference="tags" label="Tags" emptyText="-">
        <SingleFieldList placeholder="-">
          <TeamChip />
        </SingleFieldList>
      </ReferenceArrayField>
      <SelectField sortable={false} source="createPing" choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]} />
      <Fields label="Rule parameters" />
      <ColoredScoreField source="score" />
      {children}
    </Datagrid>
  );
};

export default RuleDatagrid;
