import React from 'react';
import {
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import datapointNames from '../../../../constants/datapointNames';

export const INDIVIDUAL_DATAPOINT_CONFLICT_FIELDS = [
  { id: datapointNames.FIRST_NAME, name: 'First name' },
  { id: datapointNames.LAST_NAME, name: 'Last name' },
  { id: datapointNames.DATE_OF_BIRTH, name: 'Date of Birth' },
  { id: datapointNames.ADDRESS, name: 'Address' },
  { id: datapointNames.CITY, name: 'City' },
  { id: datapointNames.CITIZENSHIP, name: 'Citizenship' },
  { id: datapointNames.RESIDENCE, name: 'Country of residence' },
];

export const BUSINESS_DATAPOINT_CONFLICT_FIELDS = [
  { id: datapointNames.COMPANY_NAME, name: 'Company Name' },
  { id: datapointNames.REGISTRATION_NUMBER, name: 'Organization Number' },
  { id: datapointNames.ADDRESS, name: 'Address' },
  { id: datapointNames.CITY, name: 'City' },
  { id: datapointNames.BENEFICIAL_OWNERS, name: 'Beneficial Owners' },
];

const DatapointConflict = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} md={6}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Individual datapoint</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <ArrayInput source="ruleParameters.individualMonitoredDatapoints" label={false}>
            <SimpleFormIterator
              inline
              disableReordering
              fullWidth
              sx={{
                '& .RaSimpleFormIterator-line': {
                  borderBottom: 'none',
                  paddingY: 2,
                  '& .RaSimpleFormIterator-inline': {
                    rowGap: '1em',
                  },
                },
              }}
            >
              <SelectInput
                source="datapointName"
                choices={INDIVIDUAL_DATAPOINT_CONFLICT_FIELDS}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={12}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Business datapoint</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <ArrayInput source="ruleParameters.businessMonitoredDatapoints" label={false}>
            <SimpleFormIterator
              inline
              disableReordering
              fullWidth
              sx={{
                '& .RaSimpleFormIterator-line': {
                  borderBottom: 'none',
                  paddingY: 2,
                  '& .RaSimpleFormIterator-inline': {
                    rowGap: '1em',
                  },
                },
              }}
            >
              <SelectInput
                source="datapointName"
                choices={BUSINESS_DATAPOINT_CONFLICT_FIELDS}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default DatapointConflict;
