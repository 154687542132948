import { ValueOf } from '../utilities/types';

const ruleNames = {
  ADDITIONAL_DELIVERY_ADDRESS: 'AdditionalDeliveryAddress',
  AFFORDABILITY: 'Affordability',
  AGE_THRESHOLD: 'AgeThreshold',
  AMOUNT_THRESHOLD: 'AmountThreshold',
  ANOMALY_DETECTION_INCOMING_AVERAGE: 'AnomalyDetectionIncomingAverage',
  ANOMALY_DETECTION_INCOMING_FREQUENCY: 'AnomalyDetectionIncomingFrequency',
  ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT: 'AnomalyDetectionIncomingSingleAmount',
  ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM: 'AnomalyDetectionIncomingVolumeRollingSum',
  ANOMALY_DETECTION_OUTGOING_AVERAGE: 'AnomalyDetectionOutgoingAverage',
  ANOMALY_DETECTION_OUTGOING_FREQUENCY: 'AnomalyDetectionOutgoingFrequency',
  ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT: 'AnomalyDetectionOutgoingSingleAmount',
  ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM: 'AnomalyDetectionOutgoingVolumeRollingSum',
  ANOMALY_DETECTION_PROCESSING_AVERAGE: 'AnomalyDetectionProcessingAverage',
  ANOMALY_DETECTION_PROCESSING_FREQUENCY: 'AnomalyDetectionProcessingFrequency',
  ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT: 'AnomalyDetectionProcessingSingleAmount',
  ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM: 'AnomalyDetectionProcessingVolumeRollingSum',
  ANOMALY_DETECTION_TOTAL_AVERAGE: 'AnomalyDetectionTotalAverage',
  ANOMALY_DETECTION_TOTAL_FREQUENCY: 'AnomalyDetectionTotalFrequency',
  ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT: 'AnomalyDetectionTotalSingleAmount',
  ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM: 'AnomalyDetectionTotalVolumeRollingSum',
  ANOMALY_DETECTION: 'AnomalyDetection',
  BUSINESS_ACTIVE: 'BusinessActive',
  BUSINESS_AUDITOR: 'BusinessAuditor',
  BUSINESS_DATA_CHANGED: 'BusinessDataChanged',
  BUSINESS_EQUITY_SHARE: 'BusinessEquityShare',
  BUSINESS_EQUITY: 'BusinessEquity',
  BUSINESS_FINANCIAL_STATEMENT_COMMENT: 'BusinessFinancialStatementComment',
  BUSINESS_FTAX: 'BusinessFtax',
  BUSINESS_PAYMENT_APPLICATIONS_SUM: 'BusinessPaymentApplicationsSum',
  BUSINESS_PAYMENT_APPLICATIONS: 'BusinessPaymentApplications',
  BUSINESS_PAYMENT_REMARKS_SUM: 'BusinessPaymentRemarksSum',
  BUSINESS_PAYMENT_REMARKS: 'BusinessPaymentRemarks',
  BUSINESS_PROVIDER_WARNING: 'BusinessProviderWarning',
  BUSINESS_SANCTION_LIST: 'BusinessSanctionList',
  BUSINESS_VAT: 'BusinessVat',
  CAR_FREQ_HISTORICAL_OWNERS: 'CarFreqHistoricalOwners',
  CAR_GUARANTOR_RELATED: 'CarGuarantorRelated',
  CAR_IN_CIRCULATION: 'CarInCirculation',
  CAR_VALUATION: 'CarValuation',
  CASH_FLOW: 'CashFlow',
  CATEGORIZATION_AMOUNT_THRESHOLD: 'CategorizationAmountThreshold',
  CO_ADDRESS: 'CoAddress',
  COLLECTOR: 'Collector',
  CONNECTED_BUYER_SELLER: 'ConnectedBuyerSeller',
  CONNECTED_ENTITY_PEP: 'ConnectedEntityPep',
  CONNECTED_ENTITY_SANCTION_LIST: 'ConnectedEntitySanctionList',
  COUNTRY_CITIZENSHIP_BLACKLIST: 'CountryCitizenshipBlacklist',
  COUNTRY_RESIDENCE_BLACKLIST: 'CountryResidenceBlacklist',
  COUNTRY_RESIDENCE_WHITELIST: 'CountryResidenceWhitelist',
  DATAPOINT_CONFLICT: 'DatapointConflict',
  EMPLOYMENT_TYPE: 'EmploymentType',
  FOREIGN_ADDRESS: 'ForeignAddress',
  INCOMING_CATEGORIZATION_FREQUENCY: 'IncomingCategorizationFrequency',
  INCOMING_CATEGORIZATION_ROLLING_SUM: 'IncomingCategorizationRollingSum',
  INCOMING_FREQUENCY: 'IncomingFrequency',
  INCOMING_VOLUME_ROLLING_SUM: 'IncomingVolumeRollingSum',
  INDIVIDUAL_CRIMINAL_RECORD: 'IndividualCriminalRecord',
  INDIVIDUAL_PEP: 'IndividualPep',
  INDIVIDUAL_SANCTION_LIST: 'IndividualSanctionList',
  IP_COUNTRY_BLACKLIST: 'IpCountryBlacklist',
  KYC_VALID: 'KycValid',
  LOW_INCOME: 'LowIncome',
  OUTGOING_CATEGORIZATION_FREQUENCY: 'OutgoingCategorizationFrequency',
  OUTGOING_CATEGORIZATION_ROLLING_SUM: 'OutgoingCategorizationRollingSum',
  OUTGOING_FREQUENCY: 'OutgoingFrequency',
  OUTGOING_VOLUME_ROLLING_SUM: 'OutgoingVolumeRollingSum',
  PROCESSING_FREQUENCY: 'ProcessingFrequency',
  PROCESSING_VOLUME_ROLLING_SUM: 'ProcessingVolumeRollingSum',
  RELATED_INDIVIDUALS_CRIMINAL_RECORD: 'RelatedIndividualsCriminalRecord',
  SELLER_OWNS_CAR: 'SellerOwnsCar',
  SNI_CODE_BLACKLIST: 'SniCodeBlacklist',
  SOURCE_OF_FUNDS: 'SourceOfFunds',
  SOURCE_OF_INCOME: 'SourceOfIncome',
  SPAR_CHANGED: 'SparChanged',
  SUSPICIOUS_FUNDS: 'SuspiciousFunds',
  TEST_BLOCK: 'TestBlock',
  TEST_REVIEW: 'TestReview',
  TIME_WINDOW: 'TimeWindow',
  TOTAL_CATEGORIZATION_FREQUENCY: 'TotalCategorizationFrequency',
  TOTAL_CATEGORIZATION_ROLLING_SUM: 'TotalCategorizationRollingSum',
  TOTAL_FREQUENCY: 'TotalFrequency',
  TOTAL_VOLUME_ROLLING_SUM: 'TotalVolumeRollingSum',
  ZIP_CODE_BLACKLIST: 'ZipCodeBlacklist',
} as const;

export type RuleNames = ValueOf<typeof ruleNames>;

export default ruleNames;
